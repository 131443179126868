// Colors from 'bootswatch/dist/cosmo/bootstrap.css'
$blue: #2780e3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #ff0039;
$orange: #f0ad4e;
$yellow: #ff7518;
$green: #3fb618;
$teal: #20c997;
$cyan: #9954bb;
$white: #fff;
$gray: #868e96;
$gray-dark: #373a3c;
$primary: #2780e3;
$secondary: #373a3c;
$success: #3fb618;
$info: #9954bb;
$warning: #ff7518;
$danger: #ff0039;
$light: #f8f9fa;
$dark: #373a3c;

$alpha: 0.7;

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Safari */
    -moz-user-select: none;      /* Old versions of Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently */
}

div.visx-tooltip {
    z-index: 10000;
}

div.popover {
    z-index: 10000;
}

div.lens-tooltip {
    box-shadow: 0 0 10px var(--dark) !important;
}

svg *.clickable-svg-element:hover {
    stroke-width: 3px !important;
}

:root {
    --blue-transparent: #{rgba(red($blue), green($blue), blue($blue), $alpha)};
    --indigo-transparent: #{rgba(red($indigo), green($indigo), blue($indigo), $alpha)};
    --purple-transparent: #{rgba(red($purple), green($purple), blue($purple), $alpha)};
    --pink-transparent: #{rgba(red($pink), green($pink), blue($pink), $alpha)};
    --red-transparent: #{rgba(red($red), green($red), blue($red), $alpha)};
    --orange-transparent: #{rgba(red($orange), green($orange), blue($orange), $alpha)};
    --yellow-transparent: #{rgba(red($yellow), green($yellow), blue($yellow), $alpha)};
    --green-transparent: #{rgba(red($green), green($green), blue($green), $alpha)};
    --teal-transparent: #{rgba(red($teal), green($teal), blue($teal), $alpha)};
    --cyan-transparent: #{rgba(red($cyan), green($cyan), blue($cyan), $alpha)};
    --white-transparent: #{rgba(red($white), green($white), blue($white), $alpha)};
    --gray-transparent: #{rgba(red($gray), green($gray), blue($gray), $alpha)};
    --gray-dark-transparent: #{rgba(red($gray-dark), green($gray-dark), blue($gray-dark), $alpha)};
    --primary-transparent: #{rgba(red($primary), green($primary), blue($primary), $alpha)};
    --secondary-transparent: #{rgba(red($secondary), green($secondary), blue($secondary), $alpha)};
    --success-transparent: #{rgba(red($success), green($success), blue($success), $alpha)};
    --info-transparent: #{rgba(red($info), green($info), blue($info), $alpha)};
    --warning-transparent: #{rgba(red($warning), green($warning), blue($warning), $alpha)};
    --danger-transparent: #{rgba(red($danger), green($danger), blue($danger), $alpha)};
    --light-transparent: #{rgba(red($light), green($light), blue($light), $alpha)};
    --dark-transparent: #{rgba(red($dark), green($dark), blue($dark), $alpha)};
}
